import * as React from "react"
import {graphql} from "gatsby";
import Image from "../../components/global/image";
import InnerLayout from "../../components/global/innerLayout"
import parse from "html-react-parser";
import "./biography.scss"

export const query = graphql`
    query ($id: [Craft_QueryArgument]) {
      craft {
        page: entry(section: "biographyPage", id: $id) {
          ... on Craft_biographyPage_default_Entry {
            seomatic {
              metaTitleContainer
              metaTagContainer
              metaLinkContainer
              metaScriptContainer
              metaJsonLdContainer
              metaSiteVarsContainer
              frontendTemplateContainer
            }
            id
            title
            uri
            firstName
            lastName
            jobTitle
            biography
            biographyImage {
              key:id
              url(transform:"biographyImageTransform")
                        ... on Craft_websiteGeneral_Asset {
                            altText
              }
            }
          }
        }
      }
    }
  `

const BiographyPage = ({ data, pageContext }) => {

    return (
        <InnerLayout seomatic={data.craft.page.seomatic} entryId={data.craft.page.id}>
            <div className="midd biography-page">

                <div className="content-page">
                    <div className="container-small">

                        <div className="content-colum-row padding-top-none">
                            <div className="sidebar">
                                {data.craft.page.biographyImage && data.craft.page.biographyImage.length > 0 &&
                                    <Image src={data.craft.page.biographyImage[0].url} alt={data.craft.page.biographyImage[0].altText}/>
                                }
                            </div>
                            <div className="content-right">
                                <div className="breadcrumb">
                                    <ul>
                                        <li><a href="/about/book-coauthors">Coauthor Biographies</a></li>
                                    </ul>
                                </div>
                                <div className="biography-content">
                                    <h2>{data.craft.page.title}</h2>
                                    {data.craft.page.jobTitle &&
                                    <h3>{data.craft.page.jobTitle}</h3>
                                    }
                                    {data.craft.page.biography && parse(data.craft.page.biography)}
                                </div>
                                <div className="biography-links">
                                    <a href="/about/book-coauthors">{`< Back to Coauthor Biographies`}</a>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </InnerLayout>
    )
}

export default BiographyPage
